import { FormSpy } from 'react-final-form'
import { equals } from 'rambda'

class AutosaveDebounceProxy extends React.Component {
  
  componentWillReceiveProps(nextProps) {
    if(equals(this.props.values, nextProps.values)) return;
    if (this.timeout) {
      clearTimeout(this.timeout)
    }
    this.timeout = setTimeout(this.save.bind(this), this.props.debounce)
  }

  async save() {
    const state = this.props.form.getState()
    const submitted = state.submitSucceeded || state.submitFailed
    const dirty = state.dirtySinceLastSubmit || !submitted
    if(dirty && !state.submiting) {
      await this.props.save(this.props.values)
    }
  }

  render() {
    return ''
  }
}

class AutosaveBlurProxy extends React.Component {
  
  componentWillReceiveProps(nextProps) {
    if (this.props.active && this.props.active !== nextProps.active) {
      this.save()
    }
  }

  async save() {
    if(!this.submiting) {
      this.submiting = true
      await this.props.save(this.props.values)
      this.submiting = false
    }
  }

  render() {
    return ''
  }

}

export const AutosaveDebounce = props => (
  <FormSpy {...props} subscription={{ values: true }} component={AutosaveDebounceProxy} />
)

export const AutosaveBlur = props => (
  <FormSpy {...props} subscription={{ values: true, active: true }} component={AutosaveBlurProxy} />
)