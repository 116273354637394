import Masonry from 'react-masonry-component'; // https://www.npmjs.com/package/react-masonry-component
import { LightgalleryProvider, LightgalleryItem } from 'react-lightgallery'; // https://github.com/VLZH/react-lightgallery
import { useDidMount } from 'react-hooks-lib';

import fsmGallery from 'application/fsm/gallery';

const image01 = '/images/gallery/certificate/image01.png';
const image02 = '/images/gallery/certificate/image02.jpg';
const image03 = '/images/gallery/certificate/image03.png';
const image04 = '/images/gallery/certificate/image04.png';
const image05 = '/images/gallery/certificate/image05.jpg';
const image06 = '/images/gallery/certificate/image06.png';
const image07 = '/images/gallery/certificate/image07.png';

const preview01 = '/images/gallery/certificate/image01_prev.png';
const preview02 = '/images/gallery/certificate/image02_prev.jpg';
const preview03 = '/images/gallery/certificate/image03_prev.png';
const preview04 = '/images/gallery/certificate/image04_prev.png';
const preview05 = '/images/gallery/certificate/image05_prev.jpg';
const preview06 = '/images/gallery/certificate/image06_prev.png';
const preview07 = '/images/gallery/certificate/image07_prev.png';

const images = [
  { preview: preview01, original: image01 },
  { preview: preview02, original: image02 },
  { preview: preview03, original: image03 },
  { preview: preview04, original: image04 },
  { preview: preview05, original: image05 },
  { preview: preview06, original: image06 },
  { preview: preview07, original: image07 },
];

const galleryConfig = {
  download: false,
  fullScreen: false,
  thumbnail: false,
  zoom: false,
};

const masonryConfig = {
  transitionDuration: 0,
};

const ImageItem = ({ image, group }) => (
  <li className="gallery__item">
    <LightgalleryItem src={image.original} group={group} downloadUrl={image.original}>
      <img src={image.preview} className="image" />
    </LightgalleryItem>
  </li>
);

const gallery = () => {
  const handleOpen = () => {
    fsmGallery.open();
  };

  const handleClose = () => {
    fsmGallery.close();
  };

  useDidMount(() => {
    const lg = document.getElementsByClassName('lg')[0];

    lg.addEventListener('onBeforeOpen', () => handleOpen());
    lg.addEventListener('onCloseAfter', () => handleClose());
  });

  return (
    <div className="container">
      <LightgalleryProvider galleryClassName="lg" lightgallerySettings={galleryConfig}>
        <Masonry
          className={'gallery'}
          elementType={'ul'}
          options={masonryConfig}
          disableImagesLoaded={false}
          updateOnEachImageLoad={false}
        >
          {images.map((image, i) => (
            <ImageItem key={i} image={image} group="sertificats" />
          ))}
        </Masonry>
      </LightgalleryProvider>
    </div>
  );
};

export default gallery;
