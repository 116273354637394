import share from 'images/application/illustrations/share.svg';
import conditions from 'images/application/illustrations/conditions.svg';

const images = {
  conditions,
  share,
};

function Title({ props }) {
  return (
    <h2
      className={`segment__title ${props.important && 'title title-important'}`}
      dangerouslySetInnerHTML={{ __html: props.title }}
    />
  );
}

function segment(props) {
  return (
    <div className="segment">
      <div className="container">
        {props.title && <Title props={props} />}

        <div className="segment__body">
          <div className="segment_grid">
            <div className={`segment_grid__left ${!props.image && 'segment_grid__left-wide'}`}>
              {props.text.map((item) => {
                return <p key={item} dangerouslySetInnerHTML={{ __html: item }} />;
              })}
            </div>

            {props.image && (
              <div className="segment_grid__right">
                <img src={images[props.image]} className="segment__image" />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default segment;
