import { Fragment } from 'react';
import { useDidMount } from 'react-hooks-lib';

import Close from 'components/UI/Close.jsx';
import Page from 'components/Page/index.jsx';

function Panel(props) {
  const handleOnMonut = () => {
    $.getScript('https://mod.calltouch.ru/init.js?id=fnfm3kl8');
  };

  useDidMount(() => handleOnMonut());

  return (
    <Fragment>
      <Close />
      <div className="page">
        <Page {...props} />
      </div>
    </Fragment>
  );
}

export default Panel;
