import cn from 'classnames';
import MediaQuery from 'react-responsive';
import Svg from 'react-inlinesvg';
import windowScrollPosition from 'window-scroll-position';
import { useState } from 'react';

import icon from 'images/application/icons/phone.svg';

const PhoneWidget = (props) => {
  const [show, setVisible] = useState(false);

  const setShow = () => setVisible(true);
  const setHide = () => setVisible(false);

  $(window).on('scroll load', () => {
    const isScroll = windowScrollPosition().top > 10;

    if (isScroll) {
      setShow();
    } else {
      setHide();
    }
  });

  return (
    <MediaQuery minDeviceWidth={768}>
      {(matches) => (
        <a
          href={`tel:${props.phone}`}
          className={cn('phone_widget__link', {
            'phone_widget__link-mobile': !matches,
            'phone_widget__link-show': show,
          })}
          title="Продавец консультант"
          onClick={() => {
            window.ym(52452166, 'reachGoal', 'phone-click');
            return true;
          }}
        >
          <Svg src={icon} className="phone_widget__icon" />

          {matches && (
            <div className="phone_widget__content">
              <span className="phone_widget__text">Продавец консультант</span>
              <span className="phone_widget__number">{props.phone}</span>
            </div>
          )}
        </a>
      )}
    </MediaQuery>
  );
};

export default PhoneWidget;
