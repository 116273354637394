import Logo from './Logo';

export default (props) => (
  <div className="order-page">
    <Container>
      <header className="order-page__header">
        <Logo />

        <h1 className="order-page__title page-title">Заказ принят</h1>
        <p>
          Номер вашего заказа
          {' '}
          <a
            href="https://algotec.ru/order/kjh324k234lkn234kj234"
            className="link link-color"
            rel="nofollow noopener noreferrer"
          >
            3453
          </a>
        </p>

        <p>Подготовка к&nbsp;отгрузке начнется как только оплата будет зачислена на&nbsp;наш счет.</p>

        <p>Для просмотра текущего статуса заказа пройдите по&nbsp;ссылке:</p>

        <p>
          <a
            href="https://algotec.ru/order/kjh324k234lkn234kj234"
            className="link link-color"
            rel="nofollow noopener noreferrer"
          >
            https://algotec.ru/order/kjh324k234lkn234kj234
          </a>
        </p>

        <p style={{ margin: '3rem 0' }}>
          Если у&nbsp;вас возникнут любые вопросы по&nbsp;поводу заказа, обращайтесь к&nbsp;нашим специалистам
          по&nbsp;телефону
          <a
            className="link link-color"
            href="tel:88005052237"
            onClick="gtag('event','phone');window.ym(52452166, 'reachGoal', 'phone-click');return true;"
          >
            8 800 505-22-37
          </a>{' '}
          или электронной почте
            {' '}
          <a href="mailto:info@algotec.ru" className="link link-color" onClick="gtag('event','email');">
            {' '}
            info@algotec.ru
          </a>
          .
        </p>
      </header>
    </Container>
  </div>

) 