import { Field } from 'react-final-form';
import { TextField, FireField, RadioButtons, SelectField, TextareaField } from 'components/Form/Fields';
import VariantSelector from './VariantSelector';
import SuggestField from 'components/Form/SuggestField';
import CitySelector from './CitySelector';
import {lazy, Suspense} from 'react';
import AdditionalField from './AdditionalField';
import ConfirmNotes from './ConfirmNotes';

const ShopsMap = lazy(() => import('additional/Map/ShopsMap'))

class DpdDeliveryForm extends React.Component {

  render() {
    const values = this.props.form.values
    const {variants, tariffs, terminals, delivery_price, delivery_price_layout, weight, kgd} = this.props.stats
    let variant = values.dpd.variant
    if(variant && !variants[variant]) variant = null 

    return (
      <>
        <header className="delivery-selector__intro">
          ВАЖНО! Доставка в&nbsp;регионы осуществляется транспортной компанией DPD, оплата доставки производится
          покупателем при получении заказа, информация о&nbsp;дате и&nbsp;времени доставке будет выслана
          по&nbsp;указанному выше телефону.
        </header>

        <Field component={CitySelector} name='dpd.locality' label="Укажите город или населенный пункт" />
        {values.dpd.locality.dpd_id === 0 && (
          <p>
            Cпособы и ориентировочная цена доставки в данный населенный пункт неизвестны, но вы можете оплатить заказ и согласовать возможность особой доставки с менеджером.
          </p>
        )}

        {variants && Object.keys(variants).length > 0 && (<>
          <Field name='dpd.variant' component={VariantSelector} items={variants} label='Выберите способ доставки' />
          <FireField name="dpd.variant" onFire={this.props.onSubmit} />
        </>)}

        {variant == 'parcel' && terminals && (
          <div className="form__row">
            <Suspense fallback='...'>
              <Field component={ShopsMap}
                name='dpd.terminal'
                city={values.dpd.locality.dpd_id}
                items={terminals}
              />
            </Suspense>
            {!values.dpd.terminal && (
              <p>Для оформления доставки выберите удобный вам Пункт выдачи заказов на карте</p>
            )}
            {values.dpd.terminal && (
              <p>{this.terminal_description(values.dpd.terminal)}</p>
            )}
          </div>
        )}

        {variant == 'courier' && (
          <>
            <div className="form__row">
              <TextField name="dpd.address" label="Адрес доставки" />
            </div>            

            <AdditionalField label="Добавить примечание для курьера"><TextareaField name="dpd.comment" /></AdditionalField>
          </>  
        )}

        {variant == 'office' && (
          <p>Самовывоз из офиса Algotec по адресу {this.props.stats.office_address}</p>
        )}

        {values.dpd.locality.dpd_id === 0 && (
          <div className="form__row">
            <TextField name="dpd.address" label="Адрес доставки" />
          </div>            
        )}

        {tariffs && (
          <div className="form__row">
            <RadioButtons name='dpd.tariff' items={tariffs} label='Выберите тариф и скорость доставки'>
              {item => `${item.name} ${item.cost} (${item.days} дней)`}
            </RadioButtons>
          </div>
        )}
        
        {delivery_price && (
          <footer className="delivery-selector__footer">
            <p>
              <strong>Ориентировочная стоимость доставки: {delivery_price}</strong>
            </p>
            {delivery_price_layout && (<p>{delivery_price_layout}</p>)}
            {this.delivery_notes(variant, weight, kgd)}
            <p>Доставка оплачивается при получении.</p>
            <p>
              Информация о дате и времени доставки 
              будет выслана по указанному выше телефону.
            </p>
          </footer>
        )}
      </>
    );
  }

  delivery_notes(variant, weight, kgd) {
    const overweight = variant == 'courier' && weight > 30
    return (<ConfirmNotes overweight={overweight} kgd={kgd} />)
  }

  terminal_description(id) {
    if(this.props.stats.terminals) {
      const item = this.props.stats.terminals.find(i => i.id == id)    
      return item ? (<span className="dpd-description"><strong>Выбранный пункт выдачи заказа: {item.name}, {item.address}.</strong> {item.description}</span>) : ''
    }
    return '' 
  }
}

export default DpdDeliveryForm;