import Masonry from 'masonry-layout';
import jQueryBridget from 'jquery-bridget';

jQueryBridget('masonry', Masonry, $);

function masonry($container) {
  const $target = $container.find('@masonry');

  const $config = {
    itemSelector: 'li',
  };

  function init() {
    $target.masonry($config);
  }

  init();
}

export default masonry;
