class FormLegend extends React.Component {
  render() {
    return (
      <div className="form_legend container">
        <h1 className="form_legend__title" dangerouslySetInnerHTML={{ __html: this.props.title }} />
        {this.props.text && this.props.text.map((item, i) => (
          <p className="form_legend__text" dangerouslySetInnerHTML={{ __html: item }} key={i} />
        ))}
        {this.props.children}
      </div>
    );
  }
}

export default FormLegend;
