import React, { Fragment } from 'react';
import { Field } from 'react-final-form';
import MessageError from 'components/Form/MessageError.jsx';

export class Label extends React.Component {
  render() {
    if (this.props.text || this.props.children) {
      return <label className="label">{this.props.text || this.props.children}</label>;
    } else {
      return '';
    }
  }
}

export class SelectField extends React.Component {
  // props.items может иметь формат [{type: type1, name: name1}, {...}]
  // или {type1: name1, ...}
  // или [name1, name2, ...]
  render() {
    let items = this.props.items;
    if ($.isPlainObject(items)) {
      items = $.map(Object.entries(items), (item) => {
        return { type: item[0], name: item[1] };
      });
    }
    return (
      <Fragment>
        <Label>{this.props.label}</Label>
        <Field
          className={`input input-select ${this.props.className}`}
          name={this.props.name}
          disabled={this.props.disabled}
          component="select"
        >
          {this.blank()}
          {this.options(items)}
        </Field>
      </Fragment>
    );
  }
  options(items) {
    if (!items || !items.map) {
      console.warn('No options in SelectField. Recived: ', items);
      return;
    }
    return items.map((item, i) => (
      <option value={item.type || item.name || i} key={i}>
        {item.name || item}
      </option>
    ));
  }
  blank() {
    if (this.props.blank) return <option />;
  }
}

export const RadioButtons = ({ name, items, label, ...props }) => {
  return (
    <div className="radio_buttons_group">
      <Label text={label} />
      {Object.keys(items).map((code, i) => (
        <Field name={name} type="radio" value={code} key={code}>
          {({ input }) => (
            <div className="radio_input">
              <label>
                <input type="radio" name={name} value={code} checked={input.checked} onChange={input.onChange} />
                {props.children(items[code])}
              </label>
            </div>
          )}
        </Field>
      ))}
    </div>
  );
};

export class TextField extends React.Component {
  constructor(props) {
    super(props);
    this.inputRef = React.createRef(null);
  }
  render() {
    const handleChange = () => {
      const strippedValue = this.inputRef.current.value.replace(/[^0-9]/g, '');
      const chars = strippedValue.split('');
      let count = 0;

      let formatted = '';

      for (const char of '+* (***) ***-**-**') {
        if (chars[count]) {
          if (/\*/.test(char)) {
            if (chars.length == 1) {
              if (chars[0] == '8' || chars[0] == '7') {
                formatted += '7';
              } else {
                formatted += '7';
                formatted += ' (';
                formatted += chars[count];
              }
            } else formatted += chars[count];
            count++;
          } else {
            formatted += char;
          }
        }
      }

      this.inputRef.current.value = formatted;
    };

    return (
      <Field name={this.props.name}>
        {({ input, meta }) => (
          <Fragment>
            <Label>{this.props.label}</Label>
            <input
              {...input}
              type="text"
              ref={this.inputRef}
              placeholder={this.props.placeholder}
              onChange={
                this.props.name == 'phone'
                  ? (e) => {
                      handleChange();
                      input.onChange(e);
                    }
                  : input.onChange
              }
              className={`input ${(meta.error || meta.submitError) && meta.touched ? 'input-error' : ''}`}
            />
            {MessageError(meta)}
          </Fragment>
        )}
      </Field>
    );
  }
}

export class TextareaField extends React.Component {
  render() {
    return (
      <Fragment>
        <Label>{this.props.label}</Label>
        <Field
          className="input input-textarea"
          component="textarea"
          rows={this.props.rows || 10}
          name={this.props.name}
          placeholder={this.props.placeholder}
        />
      </Fragment>
    );
  }
}

export class NumberField extends React.Component {
  render() {
    return (
      <Fragment>
        <Label>{this.props.label}</Label>
        <Field
          name={this.props.name}
          className="input input-number"
          component="input"
          type="number"
          min={this.props.min || 0}
          max={this.props.max || 100}
        />
      </Fragment>
    );
  }
}

class FireFieldProxy extends React.Component {
  componentWillReceiveProps(newProps) {
    if (this.props.input.value != newProps.input.value) {
      if (!this.props.debounce) {
        this.fire(newProps.input.value);
        return;
      }
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => this.fire(newProps.input.value), this.props.debounce || 1000);
    }
  }
  fire(value) {
    if (this.props.onFire) this.props.onFire({ [this.props.input.name]: value });
  }
  render() {
    return '';
  }
}

export const FireField = (props) => <Field {...props} component={FireFieldProxy} />;

export default {
  Label,
  SelectField,
  TextField,
  TextareaField,
  NumberField,
};
