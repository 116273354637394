import React from 'react';

import Container from 'components/Layouts/Container';

import image from 'images/application/illustrations/delivery.svg';
import logo01 from 'images/application/logos/dpd-logo.png';
import logo02 from 'images/application/logos/m24-logo.png';

const Logos = () => (
  <ul className="logos logos-inline">
    <li className="logos__item">
      <img src={logo01} className="logos__image" alt="" />
    </li>
    <li className="logos__item">
      <img src={logo02} className="logos__image" alt="" />
    </li>
  </ul>
);

const delivery = () => (
  <div className="delivery">
    <Container>
      <div className="delivery__container">
        <div className="delivery__left delivery__left-margin_top">
          <h2 className="delivery__title">Доставка и&nbsp;оплата</h2>
          <p>
            Мы&nbsp;производим доставку суспензии хлореллы транспортными компаниями Мегаполис24 и&nbsp;DPD. Также всегда
            можно согласовать с&nbsp;нашим менеджером особый вариант доставки. При заказе на&nbsp;сайте стоимость
            товаров оплачивается онлайн, доставка оплачивается при получении.
          </p>
        </div>

        <div className="delivery__right">
          <img src={image} className="image image-responsive" alt="" />
        </div>
      </div>

      <div className="delivery__container">
        <div className="delivery__left">
          <Logos />
        </div>

        <div className="delivery__right">
          <a href="/order" className="button button-primary button-wide">
            Заказать доставку
          </a>
        </div>
      </div>
    </Container>
  </div>
);

export default delivery;
