import createHistory from 'history/createBrowserHistory';
import fsmSlidePanel from 'application/fsm/slidePanel';

function historyAPI(url = '/') {
  const history = createHistory();
  const state = {};

  function init() {
    fsmSlidePanel.observe({
      onOpen() {
        history.push(url, state);
      },
      onClose() {
        history.goBack();
      },
    });
  }

  init();
}

export default historyAPI;
