import React from 'react';

import logo from 'images/application/brand/main_logo.svg';

const Logo = () => {
  return (
    <a href="/" title="Вернуться на главную страницу Альготек">
      <img src={logo} alt="Логотип Альготек" className="order-page__logo" />
    </a>
  );
};

export default Logo;
