import React, { useState } from 'react';
import cx from 'classnames';

import cartIcon from 'images/application/illustrations/cart.svg';

import Container from 'components/Layouts/Container';

const ImageWithText = ({ image, name, onClick, active }) => {
  const img = require(`images/application/illustrations/${image}`);
  return (
    <span className={cx('order__image_wrapper', { active })} onClick={onClick}>
      <img className="image image-responsive" src={img} alt="Альготек" />
      <p>{name}</p>
    </span>
  );
};

const ProductsList = ({ products }) => (
  <ul className="products_list">
    {products.map(({ name, price, litre_price }, i) => (
      <li className="products_list__item" key={i}>
        <div className="product_card">
          <strong className="product_card__volume">{name}</strong>
          <strong className="product_card__price">{`${price}`}</strong>
          <span className="product_card__calculation">{`${litre_price} ₽/литр`}</span>
        </div>
      </li>
    ))}
  </ul>
);

const Footer = () => (
  <div className="order__footer">
    <Container modificator="container-half_padding">
      <a
        className="button button-outline button-accent"
        role="openPanel"
        href="/methods"
        style={{ margin: '0 auto', display: 'block' }}
      >
        Методика и&nbsp;гарантия результата
      </a>
    </Container>
  </div>
);

const Content = ({ products }) => {
  const [chosenProduct, setChosenProduct] = useState(0);
  const dummyProducts = [
    {
      id: 2,
      name: 'АЛЬГОТЕК АКВА',
      description:
        'Суспензия микроводоросли - хлорелла для комплексной очистки и биологической реабилитации пресных водоемов.',
      properties: 'Штамм Chlorella Vulgaris GKO Плотность не менее 50 млн. живых клеток планктонной хлореллы на 1 мл',
      options: [
        ...products
          .filter((el) => el.name.split('/')[0] === 'aqua')
          .map((el) => ({ price: el.price, volume: el.name.split('/')[1], perL: el.litre_price, id: el.id })),
      ],
      image: 'algoaqua.png',
    },
    {
      id: 1,
      name: 'ALGOTEC SPLIT',
      description:
        'Альготек СПЛИТ - биопрепарат для очистки водоемов и устранения донных отложений. Простой и безопасный способ сделать водоем чистым, избавиться от ила и устранить неприятный запах.',
      properties: 'Cуспензионный биопрепарат нового поколения на основе комплекса сапрофитных микроорганизмов. ',
      options: [
        ...products
          .filter((el) => el.name.split('/')[0] === 'split')
          .map((el) => ({ price: el.price, volume: el.name.split('/')[1], perL: el.litre_price, id: el.id })),
      ],
      image: 'algosplitsecond.png',
      withButton: true,
    },
    {
      id: 3,
      name: 'Альготек Альгобустер',
      description:
        'Комплекс дополнительного питания на основе концентрата Chlorella Vulgaris GKO и пробиотиков. Является синбиотиком, белковой и витаминно-минеральной добавкой.',
      properties:
        ' Штамм Chlorella Vulgaris GKO Плотность не менее 100 млн. живых клеток планктонной хлореллы на 1 мл. ',
      options: [
        ...products
          .filter((el) => el.name.split('/')[0] === 'algobuster')
          .map((el) => ({ price: el.price, volume: el.name.split('/')[1], perL: el.litre_price, id: el.id })),
      ],
      image: 'algobuster.png',
      withButton: true,
    },
  ];

  const current = dummyProducts[chosenProduct];
  const handleClick = (i) => {
    setChosenProduct(i);
  };
  return (
    <div className="order order-margin">
      <div className="order__body">
        <Container modificator="container-half_padding">
          <div className="order__horizontal-scroll">
            {dummyProducts.map((prod, index) => (
              <ImageWithText
                image={prod.image}
                name={prod.name}
                key={prod.id}
                onClick={() => {
                  handleClick(index);
                }}
                active={chosenProduct === index}
              />
            ))}
          </div>
          <div className="order__product-info">
            <div className="order__product-grid">
              <div className="order__product-left">
                <h2>{current.name}</h2>
                <p>{current.description}</p>
                <p className="order__secondary-text">{current.properties}</p>
                <div className="order__options">
                  {current.options.map((option) => (
                    <div className="order__option" key={option.volume}>
                      <div>{option.volume} л </div>
                      <b>{option.price}</b>
                      <div className="per_l">{option.perL} ₽/литр </div>
                    </div>
                  ))}
                </div>

                <div className="order__buttons">
                  <a
                    role={current.name === 'ALGOTEC SPLIT' ? 'openPanel' : ''}
                    href={current.name === 'ALGOTEC SPLIT' ? '/split' : 'https://альгобустер.рф/'}
                    className={cx('info-button', { withInfoButton: current.withButton })}
                  >
                    {current.withButton ? 'Подробнее' : ''}
                  </a>
                  {current.name !== 'Альготек Альгобустер' && (
                    <a className={cx('cart-button', { withoutButton: !current.withButton })} href="/order">
                      {!current.withButton && <div>Заказать</div>}
                      <img src={cartIcon} className="cart-icon" />
                    </a>
                  )}
                </div>
              </div>
              <div className="order__product-right">
                <img className="order__image" src={require(`images/application/illustrations/${current.image}`)} />
              </div>
            </div>
          </div>
          {/* <div className="grid">
          <div className="grid__left grid__left-margin">
            <h2 className="order__title">Наш продукт</h2>

            <p className="order__subtitle">
              Суспензия микроводоросли хлорелла для комплексной очистки и&nbsp;биологической реабилитации водоемов
            </p>

            <p className="order__text">
              Штамм Chlorella Vulgaris GKO
              <br />
              Плотность 50-60&nbsp;млн. живых клеток хлореллы на&nbsp;1&nbsp;мл
            </p>
            <div className="order__main">
              <MediaQuery maxDeviceWidth={767}>
                <br />
                <br />
                <Image />
                <br />
                <br />
                <br />
              </MediaQuery>
              <ProductsList products={products} />

              <a className="button button-primary button-order" href="/order">
                Заказ доставки
              </a>
            </div>
          </div>

          <div className="grid__right">
            <MediaQuery minDeviceWidth={768}>
              <Image />
            </MediaQuery>
          </div>
        </div> */}

          {/* <Promo /> */}
        </Container>
      </div>
      <Footer />
    </div>
  );
};

const Order = ({ products, ...props }) => {
  return (
    <Container {...props} modificator="container-wide">
      <Content products={products} />
    </Container>
  );
};

export default Order;
