// https://owlcarousel2.github.io/OwlCarousel2/docs/started-welcome.html

import 'owl.carousel';

import 'owl.carousel/dist/assets/owl.carousel.css';

function carousel($container) {
  const config = {
    instafeed: {
      dots: false,
      loop: false,
      nav: true,
      navText: ['', ''],
      responsive: {
        0: {
          items: 3,
          margin: 10,
        },
        544: {
          items: 4,
          margin: 25,
        },
      },
    },
    reviews: {
      dots: true,
      items: 2,
      loop: true,
      margin: 30,
      nav: false,
      center: true,
      autoWidth: true,
      responsive: {
        0: {
          autoWidth: false,
          items: 1,
        },
        1170: {
          autoWidth: true,
          items: 2,
        },
      },
    },
  };

  function init() {
    const $targets = $container.find('@carousel');

    for (let i = 0; i < $targets.length; i += 1) {
      const $item = $targets.eq(i);
      const $config = $item.data('config') || 'default';

      $item.owlCarousel(config[$config]);

      $item.on('click', '.owl-item > div', function() {
        const $this = $(this);
        const $position = $this.data('position');

        $(this).trigger('to.owl.carousel', $position);
      });
    }
  }

  init();
}

export default carousel;
