/* Vendor */
import 'application/vendor';

/* Sylesheets */
import 'stylesheets/application.sass';

// jQuery libs
import * as Lib from 'application/jQuery/lib';

const componentRequireContext = require.context('components', true);
const ReactRailsUJS = require('react_ujs');

$(() => {
  const $container = $(document);
  const $body = $('body');
  const $header = $container.find('@header');
  const $window = $(window);

  Lib.carousel($container);
  Lib.header($window, $header);
  Lib.masonry($container);
  Lib.overlay($body);
  Lib.parallax($container);
  Lib.slidePanel($container);
});

ReactRailsUJS.useContext(componentRequireContext);
