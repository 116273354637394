import cn from 'classnames';

import { string, bool, oneOf } from 'prop-types';

const Button = (props) => {
  const {
    button_type,
    centered,
    children,
    className,
    disabled,
    fluid,
    low,
    modificator,
    onClick,
    small,
    short,
    style,
    text,
    variant,
  } = props;

  const decoration = cn('button', [`button-${variant || 'primary'}`], {
    'button-centered': centered,
    'button-fluid': fluid,
    'button-low': low,
    'button-short': short,
    'button-small': small,

    [`${className}`]: className,
    [`button-${modificator}`]: modificator,
  });

  return (
    <button
      className={decoration}
      disabled={disabled || false}
      onClick={onClick}
      style={style}
      type={button_type || 'button'}
    >
      {children || text}
    </button>
  );
};

Button.propTypes = {
  button_type: oneOf(['button', 'submit'], string),
  centered: bool,
  disabled: bool,
  low: bool,
  modificator: string,
  short: bool,
  small: bool,
  text: string,
  variant: oneOf(['primary', 'primary_inverse', 'danger', 'secondary', 'tertiary', 'simple'], string),
};

// Кнопка, залипающая на время обработки клика
export class CommandButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pending: false,
    };
  }
  render() {
    const onClick = (e) => {
      const promise = this.props.onClick(e);
      if (promise && promise.finally) {
        this.setState({ pending: true });
        promise.finally(() => this.setState({ pending: false }));
      }
    };
    return (
      <Button
        {...this.props}
        modificator={this.props.modificator || 'command'}
        disabled={this.props.disabled || this.state.pending}
        onClick={onClick}
      />
    );
  }
}

export default Button;
