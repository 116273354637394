
import { Form, Field } from 'react-final-form';
import axios from 'utils/axios';

class LoginForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      alert: false
    }
  } 
  render() {
    return (
      <div className='devise_form'>        
        <Form onSubmit={this.submit.bind(this)}>
          {(form) => (
            <div className='container'>
              <h1>Вход администратора</h1>              
              <form className='form' onSubmit={form.handleSubmit}>
                {this.alert(this.state.alert)}
                <div className='form__row'>
                  <Field name='email' className='input' component='input' type='text' placeholder='E-mail' />
                </div>
                <div className='form__row'>
                  <Field name='password' className='input' component='input' type='password' placeholder='Пароль' />
                </div>
                <div className='form__footer'>
                  <button className='button' type='submit'>Вход</button>
                </div>            
              </form>
            </div>            
          )}
        </Form>
      </div>
    )
  }
  alert(msg) {
    if(msg == false) return '';
    return (<div className='form__row form__alert'>{msg}</div>);
  }
  submit(data) {
    let promice = axios.post('/users/sign_in', {user: data});
    promice.then((res) => {
      window.location.replace('/settings')
    }).catch((error) => {
      this.setState({alert: error.response.data});
    })
  }
}

export default LoginForm
