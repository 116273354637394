import { Form, Field } from 'react-final-form';

import { AutosaveDebounce } from 'components/Form/Autosave';
import { FireField } from 'components/Form/Fields';

import DeliverySelector from './DeliverySelector';
import DpdDeliveryForm from './DpdDeliveryForm';
import MegapolisDeliveryForm from './MegapolisDeliveryForm';

class DeliveryForm extends React.Component {
  render() {
    return (
      <div className="form-card">
        <h3 className="form-card__title">3. Доставка</h3>

        <div className="form-card__body">
          <Form onSubmit={this.props.onSubmit.bind(this)} initialValues={this.props.delivery}>
            {(form) => {
              const renderForm = type => (
                <div className="delivery-selector__body">
                  {type === 'megapolis' && (
                    <MegapolisDeliveryForm 
                      form={form}
                      zone={form.values.megapolis.zone}
                      stats={this.props.stats} />
                  )}
                  {type === 'dpd' && (
                    <DpdDeliveryForm 
                      form={form}
                      stats={this.props.stats}
                      onSubmit={form.handleSubmit} />
                  )}                        
                </div>
              )
              return (
                <form className="form form-delivery" onSubmit={form.handleSubmit}>
                  {this.props.stats.weight == 0 && (
                    <div className='form__row'>Добавьте товары в заказ перед выбором способа доставки</div>
                  )}
                  {this.props.stats.weight > 0 && (
                    <>
                      <h4 className="form-delivery__title">Выберите зону доставки</h4>
                      <AutosaveDebounce debounce={1000} save={form.handleSubmit} />
                      <Field
                        component={DeliverySelector}
                        name="delivery_type"
                        types={this.props.delivery_types}
                        renderForm={renderForm}
                      />
                      <FireField name="delivery_type" onFire={form.handleSubmit} />
                    </>  
                  )}
                </form>
              );
            }}
          </Form>
        </div>
      </div>
    );
  }
}

export default DeliveryForm;
