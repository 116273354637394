import { string, shape, arrayOf, number } from 'prop-types';

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

const faq = ({ questions, title }) => {
  return (
    <div className="container">
      <div className="content">
        {title && <h2>{title}</h2>}

        <Accordion allowMultipleExpanded allowZeroExpanded="false">
          {questions.map(({ question, answer, id }) => (
            <AccordionItem key={id}>
              <AccordionItemHeading>
                <AccordionItemButton>
                  <h4 className="accordion__title" dangerouslySetInnerHTML={{ __html: question }} />
                </AccordionItemButton>
              </AccordionItemHeading>

              <AccordionItemPanel>
                <p dangerouslySetInnerHTML={{ __html: answer }} />
              </AccordionItemPanel>
            </AccordionItem>
          ))}
        </Accordion>
      </div>
    </div>
  );
};

faq.propTypes = {
  title: string,
  questions: arrayOf(
    shape({
      id: number.isRequired,
      question: string.isRequired,
      answer: string.isRequired,
    }),
  ).isRequired,
};

faq.defaultProps = {
  title: null,
};

export default faq;
