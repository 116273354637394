// https://github.com/thecreation/jquery-slidePanel

import 'jquery-slidePanel/dist/css/slidePanel.min.css';

import 'jquery-slidePanel/dist/jquery-slidePanel.min';

import axios from 'utils/axios';
import Panel from 'components/Panel/index.jsx';
import ReactDOM from 'react-dom';

import fsmGallery from 'application/fsm/gallery';
import fsmSlidePanel from 'application/fsm/slidePanel';
import historyAPI from 'utils/history';

function slidePanel($container) {
  function closePanel() {
    if (fsmGallery.is('closed')) {
      $.slidePanel.hide();
    }
  }

  function showPanel(url) {
    axios.get(`${url}.json`).then((res) => {
      ReactDOM.render(<Panel page={res.data} isPanel={true} />, $('.slidePanel .slidePanel-content')[0]);
    });

    $.slidePanel.show(
      {},
      {
        mouseDrag: null,
        touchDrag: null,
        pointerDrag: null,
        beforeShow() {
          fsmSlidePanel.open();
        },
        beforeHide() {
          ReactDOM.unmountComponentAtNode($('.slidePanel .slidePanel-content')[0]);
        },
        afterHide() {
          fsmSlidePanel.close();
        },
      },
    );
  }

  function init() {
    const $trigger = $container.find('@openPanel');

    $container.on('keyup', (e) => {
      if (e.key === 'Escape') {
        closePanel();
      }
    });

    $container.on('click', '@closePanel', () => closePanel());

    $trigger.on('click', (e) => {
      e.preventDefault();

      const $this = $(e.target);
      const url = $this.attr('href');

      historyAPI(url);
      showPanel(url);
    });
  }

  init();
}

export default slidePanel;
