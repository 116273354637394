import Content from './Content.jsx';
import Header from './Header.jsx';

function Calculation() {
  return (
    <div className="segment segment-color">
      <div className="calculation container">
        <div className="calculation__header">
          <Header />
        </div>

        <div className="calculation__body">
          <Content />
        </div>

        <strong className="calculation__footer">
          ВАЖНО! В соответствии с нашей методикой мы рекомендуем трехкратное внесение альголизанта, тогда мы даем
          гарантию возврата средств в случае отсутствия заметного результата. Однако, хлорелла эффективна и при
          первоначальном внесении в летнее время, когда водоем уже «цветет».
        </strong>
      </div>
    </div>
  );
}

export default Calculation;
