import React from 'react';
import { object } from 'prop-types';
import Container from 'components/Layouts/Container';
import { CommandButton } from 'components/UI/Button';
import axios from 'utils/axios';

import OrderTable from './OrderTable';
import ConfirmNotes from './ConfirmNotes';
import Logo from './Logo';

const submitPayment = async (id) => {
  return await axios.post(`/order/${id}/checkout`).then((res) => {
    window.location.assign(res.data.payment_url);
  }).catch(e => {console.log(e)})
}

const editOrder = () => {
  window.location.assign('/order')
}

const ConfirmOrder = ({cart, stats}) => {

  const is_dpd = cart.delivery.type == 'dpd'
  const is_courier = is_dpd && cart.delivery.dpd.variant == 'courier'
  const dpd_courier_overweight = is_courier && stats.weight > 30
  const dpd_kgd = is_dpd && stats.kgd

  return (
    <div className="order-page">
      <Container>
        <header className="order-page__header">
          <Logo />
          <h1 className="order-page__title page-title">Подтверждение заказа</h1>
          <p>Пожалуйста, внимательно проверьте введенные вами данные еще раз перед оплатой заказа.</p>
        </header>

        <div className="form-card">
          <div className="form-card__body form-card__body--gray">
            <div className="confirm-order">
              <div className="confirm-order__body">
                <OrderTable {...cart} />
              </div>

              <ConfirmNotes overweight={dpd_courier_overweight} kgd={dpd_kgd} />

              <footer className="confirm-order__footer">
                <CommandButton type="submit" onClick={() => submitPayment(cart.id)} modificator="wide">
                  Все верно, оплатить товары
                  {` (${cart.products.price})`}
                </CommandButton>

                <button className="link link-color confirm-order__edit" onClick={editOrder} type="button">
                  Есть неточности, вернуться к&nbsp;форме
                </button>
              </footer>
            </div>
          </div>
        </div>
      </Container>
    </div>
  )  
};

ConfirmOrder.propTypes = {
  cart: object.isRequired,
  stats: object.isRequired
};

export default ConfirmOrder;
