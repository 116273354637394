import Container from 'components/Layouts/Container';
import {Form} from 'react-final-form';
import {TextField} from 'components/Form/Fields';
import {CommandButton} from 'components/UI/Button';
import axios from 'utils/axios';

const checkAccess = (id, credentials) => {
  return axios.post('/order/access', {id, credentials})
    .then(({data}) => {window.location.assign(data.url)})
    .catch((err) => {if(err.response) return err.response.data})
}

const AccessForm = ({order_id}) => {
  return (
    <div className="order-page">
      <Container>
        <div className="form-card">
          <div className="form-card__body form-card__body--gray">
            <h3>Доступ к странице заказа ограничен</h3>
            <p>
            Информация о заказе содержит персональные данные, которые мы можем показать только самому клиенту, сделавшему заказ.
            Чтобы подтвердить, что это ваш заказ, введите E-mail или телефон, указанные в заказе.</p>
            <Form initialValues={{}} onSubmit={(data) => (checkAccess(order_id, data.credentials))}>
              {(form) => (
                <form className="form" onSubmit={form.handleSubmit}>
                  <div className="form__row">
                    <TextField name="credentials" label="Емейл или телефон заказа" />
                  </div>
                  {form.submitErrors && (
                    <div className='order-page__error'>{form.submitErrors}</div>
                  )}
                  <div className="form__row">
                    <CommandButton onClick={form.handleSubmit} disabled={!form.values.credentials}>Проверить</CommandButton>
                  </div>
                </form>
              )}
            </Form>            
          </div>
        </div>
      </Container>
    </div>
  )
}

export default AccessForm;