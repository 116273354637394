import React from 'react';

const Row = ({ product: { name, amount, price, note } }) => (
  <div className="order-table__row">
    <div className="order-table__cell">
      {`Канистра суспензии ${name} (${amount} шт.)`}
      {note && <p className="order-table__note">{note}</p>}
    </div>
    <div className="order-table__cell">{price}</div>
  </div>
);

const Products = ({items}) => {
  return (      
    <section className="order-table__group">
      <h3 className="order-table__title">Товары</h3>
      {items.length == 0 && (
        <div>(Товары не выбраны)</div>
      )}
      {items.map((product, i) => (
        <Row product={product} key={i} />
      ))}
    </section>
  )  
}

const Status = ({status, status_string}) => {
  return (
    <section className="order-table__group">
      <div className="order-table__row wider">
        <div className="order-table__cell">Статус заказа</div>
        <div className="order-table__cell">{status_string}</div>
      </div>
    </section>    
  )
}

const Contact = ({name, surname, phone, email}) => {
  return (
    <section className="order-table__group">
      <h3 className="order-table__title">Контакты</h3>
      <p>{name && surname && `${surname} ${name}` || 'Имя не указано'}</p>
      <p>{phone || "Телефон не указан"}</p>
      <p>{email || "Е-мейл не указан"}</p>
    </section>
  )
}

const DpdCourier = ({address, comment, tariff}) => {
  return (
    <div>
      <p>Тариф: {tariff}</p>
      <p>Адрес: {address || 'Не указан'}</p>
      <p>Комментарий курьеру: {comment || 'Не указан'}</p>
    </div>
  )
}

const DpdParcel = (props) => {
  const {locality, terminal, tariff} = props
  return (
    <div>
      <p>Тариф: {tariff}</p>
      <p>Пункт выдачи: {terminal.name} ({locality}, {terminal.address})</p>
      <p>{terminal.description}</p>
    </div>
  )
}

const DpdOffice = ({address}) => {
  return (
    <div>
      <p>Самовывоз из офиса Algotec по адресу {address}</p>
    </div>    
  )  
}

const DpdUnknown = ({locality, address}) => {
  return (
    <div>
      <p>Населённый пункт: {locality}</p>
      <p>Адрес доставки: {address}</p>
      <p>Cпособы и ориентировочная цена доставки в данный населенный пункт неизвестны, но вы можете оплатить заказ и согласовать возможность особой доставки с менеджером.</p>
    </div>    
  )  
}

const Megapolis = ({address, full_address, appartment, zone_name, date, time}) => {
  return (
    <div>
      <p>Адрес: {full_address || address}</p>
      <p>{zone_name}</p>
      {appartment && (<p>Квартира: {appartment}</p>)}
      <p>Дата доставки: {date} {time}</p>
    </div>
  )
}

const Delivery = (props) => {
  const {area, name, type, price, price_layout, dpd, megapolis} = props
  return (
    <section className="order-table__group">
      <h3 className="order-table__title">Доставка</h3>
      <p>{`${area} (${name})`}</p>
      {type == 'dpd' && dpd.variant == 'courier' && (<DpdCourier {...dpd} />)}
      {type == 'dpd' && dpd.variant == 'parcel' && (<DpdParcel {...dpd} />)}
      {type == 'dpd' && dpd.variant == 'office' && (<DpdOffice {...dpd} />)}
      {type == 'dpd' && dpd.city_id == 0 && (<DpdUnknown {...dpd} />)}
      {type == 'megapolis' && (<Megapolis {...megapolis} />)} 
      {price && (
        <div className="order-table__row order-table__row--delivery">
          <div className="order-table__cell">Ориентировочная стоимость доставки (оплачивается при получении)</div>
          <div className="order-table__cell">{price}</div>
        </div>
      )}
      <br />
      {price_layout && (
        <div className='order-table__row order-table__row--delivery'>
          <div className='order-table__cell'>{price_layout}</div>
          <div className='order-table__cell' />
        </div>
      )}
      {type == 'dpd' && dpd.variant != 'office' && dpd.city_id != 0 && (
        <p>Информация о дате и времени доставки будет выслана по указанному выше телефону.</p>
      )}
      {type == 'megapolis' && (
        <p>Информация о доставке будет выслана по указанному выше телефону. 
        Курьер осуществляет звонок за 15-30 минут до прибытия. Простой 15 минут стоит 100 рублей, 
        первые 15 минут бесплатно.</p>
      )}
    </section>
  )
}

const OrderTable = ({ products, contact, delivery, status, status_string, ...props }) => {
  return (
    <div className="order-table">
      <Products items={products.items} />
      {props.showStatus && (
        <Status status={status} status_string={status_string} />
      )}
      <Contact {...contact} />
      <Delivery {...delivery} />
    </div>
  );
};

export default OrderTable;
