
const ConfirmNotes = ({overweight, kgd}) => {
  return (overweight || kgd) ? (
    <div className="confirm-order__notes">
      {overweight && (
        <p>
          ВАЖНО! Вес вашего заказа превышает 30кг, при курьерской доставке к&nbsp;стоимости добавляются
          погрузочно-разгрузочные работы в&nbsp;размере 400 руб. (
          <a href="https://www.dpd.ru/dpd/uslugi-i-tarify/dostavka-po-rossii/opcii/pogruzka-razgruzka-posylok.do2" className="link" rel="nofollow noopener noreferrer">
            подробнее о&nbsp;ПРД на&nbsp;сайте DPD
          </a>
          ).
        </p>
      )}
      {kgd && (
        <p>
          За&nbsp;доставку каждой канистры по&nbsp;30л к&nbsp;стоимости добавляются по&nbsp;1590 руб
          за&nbsp;крупно-габаритную доставку (
          <a href="https://www.dpd.ru/dpd/uslugi-i-tarify/dostavka-po-rossii/opcii/kgt.do2" className="link" rel="nofollow noopener noreferrer">
            подробнее о&nbsp;КГД на&nbsp;сайте DPD
          </a>
          ).
        </p>
      )}
    </div>
  ) : ''
}

export default ConfirmNotes;