import { Fragment } from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

export class HiddenGroupContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: this.props.open || false
    }
  }
  render() {
    return (<HiddenGroup
      open={this.state.open}
      label={this.props.label}
      openLabel={this.props.openLabel}
      onToggle={this.toggle.bind(this)}
      children={this.props.children}
    />)
  }
  toggle() {
    this.setState({open: !this.state.open})
  }
}

export class HiddenGroup extends React.Component {
  render() {
    return(
      <Fragment>
        <div className="form__row">
          <button type="button" className="form__link" onClick={this.props.onToggle}>
            {this.props.open ? (this.props.openLabel || this.props.label || 'Закрыть') : (this.props.label || 'Открыть')}
          </button>
        </div>

        <ReactCSSTransitionGroup
          transitionName="react_fades"
          transitionEnterTimeout={500}
          transitionLeaveTimeout={300}
        >
          {this.props.open && (this.props.children)}
        </ReactCSSTransitionGroup>
      </Fragment>
    )
  }
}
