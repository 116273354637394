import BackHome from './blocks/BackHome.jsx';
import Calculation from './blocks/Calculation/index.jsx';
import delivery from './blocks/delivery.jsx';
import faq from './blocks/faq.jsx';
// import formConsultations from './blocks/formConsultations.jsx';
import OrderForm from 'components/Order/OrderForm.jsx';
// import order from 'components/Order/Order.jsx'; // deprecated?
import gallery from './blocks/gallery.jsx';
import header from './blocks/header.jsx';
import html from './blocks/html.jsx';
import iframe from './blocks/iframe.jsx';
import page_intro from './blocks/pageIntro.jsx';
import segment from './blocks/segment.jsx';
import service from './blocks/service.jsx';
import stub from './blocks/stub.jsx';
import summary from './blocks/summary.jsx';
import split from './blocks/split.jsx';

class PlainTextBlock extends React.Component {
  render() {
    return <div>{this.props.text}</div>;
  }
}

export default {
  back_home: BackHome,
  calculation: Calculation,
  delivery,
  faq,
  // formConsultations,
  formOrder: OrderForm,
  gallery,
  header,
  html,
  iframe,
  // order,
  page_intro,
  plaintext: PlainTextBlock,
  segment,
  service,
  stub,
  summary,
  split,
};
