import React from 'react';
import { number, object } from 'prop-types';
import Container from 'components/Layouts/Container';
import axios from 'utils/axios';
import Logo from './Logo';

const ThankOrder = ({ cart }) => {
  const { number } = cart;
  return (
    <div className="order-page">
      <Container>
        <header className="order-page__header">
          <Logo />
          <h1 className="order-page__title page-title">Заказ №{number} оформлен</h1>
          <p>
            Мы благодарим Вас за ваше доверие. Мы обязательно свяжемся с Вами для уточнения деталей заказа. А пока вы
            ждете, можете посмотреть наши соц.сети, там очень интересно :)
          </p>
        </header>

        <div className="form-card">
          <button
            onClick={() => {
              window.location.assign('/');
            }}
            className="button button-primary button-header"
          >
            Перейти на главную
          </button>
        </div>
      </Container>
    </div>
  );
};

ThankOrder.propTypes = {
  cart: { number: number },
};

export default ThankOrder;
