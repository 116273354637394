import Blocks from './blocks.jsx';

class Page extends React.Component {
  render() {
    const isPanel = this.props.isPanel || false;
    const blocks = this.props.page.blocks;
    const blocksForPanel = R.reject(b => b.hide_in_panel);
    const blocksList = isPanel ? blocksForPanel(blocks) : blocks;

    return <div style={{ marginTop: isPanel ? 0 : '25px' }}>{blocksList.map(this.renderBlock)}</div>;
  }

  renderBlock(block, i) {
    return <PageBlock key={i} type={block.type} props={block} />;
  }
}

class PageBlock extends React.Component {
  render() {
    const Block = Blocks[this.props.type];
    if (Block) {
      return <Block {...this.props.props} />;
    }
    return <div>{this.props.type}</div>;
  }
}

export default Page;
