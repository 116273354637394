import './zones_map.sass';
import ymaps from 'ymaps';
import axios from 'utils/axios';
import debounce from 'utils/debouncing';

const precisionText = (prec) => {
  const strs = {
    'number': 'Уточните номер дома, строения или корпуса',
    'near': 'Уточните номер дома', 
    'range': 'Уточните адрес',
    'street': 'Укажите полный адрес',
    'other': 'Введите адрес'
  }
  return (prec == 'exact') ? '' : (strs[prec] || strs.other)   
}

const getZone = (coords, zones) => {
  const zone = zones.searchContaining(coords).get(0)
  if(zone) {
    return zone.properties.getAll()
  }
  return {zone: 6, description: 'За пределами зоны доставки'}
}

const closest = (maps, objects, center) => {
  let obj = null, dis = 200000
  objects.each((o) => {
    const distance = maps.coordSystem.geo.getDistance(o.geometry.getCoordinates(), center)
    if(dis > distance) {
      obj = o
      dis = distance
    }
  })
  return obj
}

const buildAddress = (components) => {
  let selected = []
  components.forEach((c) => {
    if(c.kind == 'locality' && c.name != 'Москва') { selected.push(c.name) }
    if(c.kind == 'street' || c.kind == 'house') { selected.push(c.name)}
  })
  return selected.join(', ')
}

const initMap = async (input, container) => {
  const [maps, res] = await Promise.all([
    ymaps.load('https://api-maps.yandex.ru/2.1/?lang=ru_RU&coordorder=longlat&apikey=b2c2adcc-c876-4625-9024-856b28635c8d'),
    axios.get('/megapolis.geojson')
  ])
  const geodata = res.data
  const center = geodata.userMap.map.center
  const restrict = [
    [center[0]-2.0, center[1]-1.0],
    [center[0]+2.0, center[1]+1.0]
  ]
  const map = new maps.Map(container, {
    center: center,
    zoom: geodata.userMap.map.zoom,
    controls: ['geolocationControl', 'zoomControl'],
  }, {
    restrictMapArea: restrict,
    zoomControlPosition: { right: 10, top: 50 }, 
    zoomControlSize: 'small'
    // zoomControlPosition: {left: 'auto', right: '10'}
  })
  map.events.add('boundschange', (e) => {
    const zoom = e.get('newZoom')
    const opacity = zoom >= 12 ? Math.min(0, (20 - zoom) / 8) : 1.0
    zones.each((obj) => {obj.options.set({fillOpacity: obj.properties.get('fill').opacity * opacity})})  
  })
  const zones = maps.geoQuery(geodata.userMap).addToMap(map)
  zones.each((obj) => {
    obj.options.set({
      fillColor: obj.properties.get('fill').color,
      fillOpacity: obj.properties.get('fill').opacity,
      strokeColor: obj.properties.get('stroke').color,
      strokeWidth: obj.properties.get('stroke').width,
      strokeOpacity: obj.properties.get('stroke').opacity
    });
    // obj.properties.set('balloonContent', obj.properties.get('desciption'));    
  });
  return {
    onClick: (callback) => {
      const click = (e) => {
        const coords = e.get('coords');
        const zone = getZone(coords, zones)
        callback(maps, coords, zone)
      }
      map.events.add('click', click)
      zones.each((o) => o.events.add('click', click))  
    }, 
    openBalloon: (coords, address, description) => {
      map.balloon.open(coords, {content: `<h3>${address}</h3><pre>${description}</pre>`})
    },
    geocode: async (address) => {
      const res = await maps.geocode(address, {strictBounds: true, boundedBy: restrict, kind: 'house', results: 3})
      const obj = closest(maps, res.geoObjects, center)
      const result = {}
      if(obj) {
        result.props = obj.properties.getAll()
        result.coords = obj.geometry.getCoordinates()
        result.prec = obj.properties.get('metaDataProperty.GeocoderMetaData.precision')
        result.error = precisionText(result.prec)
        if(result.prec == "exact") {
          result.zone = getZone(result.coords, zones)
        }
      } else {
        result.error = "Адрес не найден, пожалуйста возпользуйтесь доставкой через ТК ДПД в любые регионы (блок ниже) или свяжитесь с нашим менеджером для обсуждения особой доставки."
      }
      return result
    },
    setBounds: (bounds) => {
      map.setBounds(bounds)
    },
    destroy: () => {
      map.destroy()
      // suggest.destroy()
  }}
}

//====================================================================//
class YandexMap extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      error: ''
    }
    this.container = React.createRef()
    this.input = React.createRef()
    this.geocode = debounce(this.checkAddress.bind(this), 1000)
  }
  componentDidMount() {
    initMap(this.input.current, this.container.current).then((res) => {
      this.yandexMap = res
      this.yandexMap.onClick((maps, coords, zone) => {
        maps.geocode(coords, {kind: 'house', results: 1}).then((res) => {
          const obj = res.geoObjects.get(0)
          if(obj) {
            const props = obj.properties.getAll()
            const address = buildAddress(props.metaDataProperty.GeocoderMetaData.Address.Components)
            const full_address = props.text
            this.props.input.onChange({address: address, full_address: full_address, zone: zone.zone})
            this.yandexMap.setBounds(props.boundedBy)
            this.yandexMap.openBalloon(coords, address, zone.description)
            this.setState({error: null})
          }
        })
      })
      if(this.props.input.value.address) {
        this.checkAddress(this.props.input.value.address)
      }
    })  
  }
  componentWillUnmount() {
    if(this.yandexMap) { this.yandexMap.destroy() }
  }
  render() {
    const value = this.props.input.value
    return (
      <div className='zones_map'>
        <div className='form__row'>
          <label className='label'>Укажите населённый пункт, улицу и номер дома</label>
          <input 
            type='text' 
            ref={this.input} 
            className='input' 
            value={value.address}
            onChange={this.addressChange.bind(this)} />
          {this.state.error && (<div><strong>{this.state.error}</strong></div>)}
        </div> 
        <div className='ymap_container' ref={this.container}></div>
        {value.zone && (
          <div className='zone_name'>{this.zoneName(value.zone)}</div>
        )}
        {value.full_address && (
          <div className='full_address'>Полный адрес: {value.full_address}</div>          
        )}
      </div>
    )    
  }
  addressChange() {
    const address = this.input.current.value
    this.props.input.onChange({...this.props.input.value, address})
    this.geocode(address)
  }

  checkAddress(address) {
    this.yandexMap.geocode(address).then((res) => {
      if(res.props) {
        this.yandexMap.setBounds(res.props.boundedBy)
        if(res.zone) {
          this.yandexMap.openBalloon(res.coords, address, res.zone.description)
          if(this.props.input.value.zone != res.zone.zone || this.props.input.value.full_address != res.props.text) {
            this.props.input.onChange({...this.props.input.value, full_address: res.props.text, zone: res.zone.zone})
          }
        }
      } else {
        this.props.input.onChange({...this.props.input.value, full_address: '', zone: null})
      }
      this.setState({error: res.error})
    })
  }
  zoneName(zone) {
    const names = {
      1: 'Зелёная зона доставки М24',
      2: 'Жёлтая зона доставки М24',
      3: 'Оранжевая зона доставки М24',
      4: 'Красная зона доставки М24',
      5: 'Синяя зона доставки М24',
    }
    return names[zone] || ''
  }
} 

export default YandexMap;

