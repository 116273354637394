import cn from 'classnames';
import React from 'react';

import Button from 'components/UI/Button';

class DeliverySelector extends React.Component {
  render() {
    const {
      types, 
      renderForm,
      input: { value, onChange },
    } = this.props;

    return (
      <>
        {Object.keys(types).map((type, i) => {
          const {area, name} = types[type]
          const isActive = (value == type)
          const decorationButton = cn('delivery-selector__button', {
            'delivery-selector__button--active': isActive,
            'delivery-selector__button--disabled': !isActive && value,
          });
          const decorationArea = cn('delivery-selector__area', {
            'delivery-selector__area--active': isActive,
          });
          return (
            <div key={i} className="delivery-selector">
              <Button
                className={decorationButton}
                onClick={() => onChange(type)}
              >
                <div className="delivery-selector__left">
                  <span className={decorationArea}>{area}</span>
                </div>
                <span className="delivery-selector__name">{name}</span>
              </Button>
              {isActive && renderForm(type)}
            </div>
          )
        })}
      </>  
    );
  }
}

export default DeliverySelector;
