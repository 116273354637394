import React from 'react';
import { CommandButton } from 'components/UI/Button';
import axios from 'utils/axios';

import Container from 'components/Layouts/Container';

import OrderTable from './OrderTable';
import AccessForm from './AccessForm';
import Logo from './Logo';

const submitPayment = async (id) => {
  return await axios.post(`/order/${id}/pay`).then((res) => {
    window.location.assign(res.data.payment_url);
  }).catch(e => {console.log(e)})
}


const OrderPage = ({ cart }) => {
  if(cart.confirm_access) {
    return (<AccessForm order_id={cart.id} />)
  }
  return (
    <div className="order-page">
      <Container>
        <header className="order-page__header">
          <Logo />
          <h1 className="order-page__title page-title">Заказ №{cart.number}</h1>
        </header>

        <div className="form-card">
          <div className="form-card__body form-card__body--gray">
            <OrderTable {...cart} showStatus />
           {cart.status == 'created' && (
             <CommandButton type="submit" onClick={() => submitPayment(cart.id)} modificator="wide">
               Oплатить товары {` (${cart.products.price})`}
             </CommandButton>
            )}
          </div>
        </div>
        <footer className="order-page__footer order-page__footer--wide">
          <p>
            Внимание! Не&nbsp;сообщайте посторонним лицам ссылку для просмотра данного заказа, так как он&nbsp;содержит персональные данные.</p>
           <p>Если возникнут любые вопросы по&nbsp;поводу заказа, обращайтесь к&nbsp;продавцам-консультантам 
              по&nbsp;телефону <nobr className="phone">8-800-505-22-37</nobr> или электронной почте info@algotec.ru.</p>   
        </footer>
      </Container>
    </div>
  );
};

export default OrderPage;
