import fsmSlidePanel from 'application/fsm/slidePanel';

function overlay($container) {
  const duration = 'fast';

  function toggleBodyScroll() {
    $('html, body').toggleClass('body-stop_scroll');
  }

  function create($el) {
    $el.appendTo($container);
  }

  function show($el) {
    $el.fadeIn(duration);
  }

  function remove($el) {
    $el.fadeOut(duration);
  }

  function init() {
    const $el = $('<div/>', {
      class: 'overlay',
      id: 'overlay',
      role: 'overlay closePanel',
    });

    create($el);

    fsmSlidePanel.observe({
      onOpen() {
        show($el);
        toggleBodyScroll();
      },
      onClose() {
        remove($('@overlay'));
        toggleBodyScroll();
      },
    });
  }

  init();
}

export default overlay;
