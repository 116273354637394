import React, { useState } from 'react';

const AdditionalField = ({ label, children }) => {
  const [isOpen, setOpen] = useState(false);

  const handleToogle = () => setOpen(!isOpen);

  return (
    <div className="form__row">
      <button type="button" className="link link-color" onClick={handleToogle}>
        {label}
      </button>

      {isOpen && <div className="additional-field">{children}</div>}
    </div>
  );
};

export default AdditionalField;
