function stub(props) {
  return (
    <div className="container">
      <div className="content">
        <h2>{props.title}</h2>
        <p>Текст готовится</p>
      </div>
    </div>
  );
}

export default stub;
