import React from 'react';

function Header() {
  return (
    <>
      <div className="calculation__column">
        <h2 className="segment__title">Расчет стоимости очистки водоема хлореллой по рекомендованной методике</h2>
      </div>
      <div className="calculation__column">
        <p>
          Наш планктонный штамм Chlorella Vulgaris GKO, живет в&nbsp;поверхностном слое воды на&nbsp;глубине
          до&nbsp;полутора метров, поэтому расчет необходимого количества суспензии производится исходя из&nbsp;площади
          поверхности пруда.
        </p>
      </div>
    </>
  );
}

export default Header;
