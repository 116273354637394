import no_algae from 'images/application/illustrations/no_algae.png';

const images = {
  no_algae,
};

function pageIntro(props) {
  return (
    <div className={!props.wide && 'container'}>
      <div className="page_intro">
        <img src={images[props.image]} className="page_intro__image" alt="Нет синим водорослям" />
        {props.text.map((item) => {
          return <p key={item} dangerouslySetInnerHTML={{ __html: item }} />;
        })}
      </div>
    </div>
  );
}

export default pageIntro;
