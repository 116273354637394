import { Field } from 'react-final-form';
import { TextField } from 'components/Form/Fields.jsx';

class SuggestField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: null,
    };
  }
  render() {
    return (
      <div className="suggest_field">
        <TextField {...this.props} onChange={this.onChange.bind(this)} onKeyPress={this.onKeyPress.bind(this)} />
        {this.renderSelect(this.state.items)}
      </div>
    );
  }
  onKeyPress(e) {
    // if(e.key == 'Enter') {
    //   if(this.props.onNotFound) this.props.onNotFound(e.target.value)
    // }
  }
  renderSelect(items) {
    if (!items || items.length == 0) return '';
    return <div className="suggest_select">{items.map(this.renderItem.bind(this))}</div>;
  }
  renderItem(item, i) {
    return (
      <div className="suggest_item" key={i} onClick={() => this.select(item)}>
        {this.props.itemView(item)}
      </div>
    );
  }
  select(item) {
    this.setState({ items: null });
    if (this.props.onSelect) {
      this.props.onSelect(item);
    }
  }
  onChange(value) {
    if (this.promice && this.promice.reject) {
      this.promice.reject();
      this.promice = null;
    }
    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = null;
    }
    if (this.state.items) {
      this.setState({ items: null });
    }
    this.timeout = setTimeout(() => this.searchSuggest(value), this.props.debounce || 1000);
  }
  searchSuggest(value) {
    if (value) {
      this.promice = BackendClient.suggest(this.props.endpoint, value)
        .then((res) => {
          const items = res.data.items;
          if (!items || items.length === 0) {
            if (this.props.onNotFound) this.props.onNotFound(value);
          } else if (items.length === 1) {
            this.select(items[0]);
          } else {
            this.setState({ items });
          }
        })
        .finally(() => {
          this.promice = null;
        });
    }
  }
}

export default SuggestField;
