import React from 'react';

import Fields from 'components/Form/Fields';

const ProductCounter = ({ name, price, litrePrice, id, quantities }) => {
  return (
    <div className="product-counter">
      <div className="product-counter__left">
        <span className="product-counter__title">{name}л</span>

        <div className="product-counter__subtite">
          <span className="product-counter__price">{price}</span>

          <span className="product-counter__hint">
            {litrePrice}
            ₽/литр
          </span>
        </div>
      </div>

      <div className="product-counter__count">
        <div>
          <Fields.SelectField name={id} items={quantities} />
        </div>
        <span>шт.</span>
      </div>
    </div>
  );
};

export default ProductCounter;
