import { Fragment } from 'react';

function service(props) {
  const text = 'У&nbsp;нас новый сервис&nbsp;&mdash; биохимический анализ воды';

  return (
    <Fragment>
      <div className="service">
        <div className="container">
          <div className="service__container">
            <div className="service__left">
              <span className="service__title" dangerouslySetInnerHTML={{ __html: props.text || text }} />
            </div>
            <div className="service__right">
              <a className="button button-inverse" href="/aquacheck" role="openPanel">
                Аквачек
              </a>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default service;
