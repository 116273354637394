const Promo = () => (
  <div className="promo">
    <div className="promo__content">
      {/* <strong className="text text-accent text-uppercase text-label"> */}
      {/*   Важно! */}
      {/* </strong>{' '} */}
      {/* <span className="text text-accent text-brand">Апрель и май</span> */}
      {/* {' '} */}
      {/* <span className="text text-accent">&mdash;&nbsp;идеальное время для внесения хлореллы в&nbsp;водоем.</span> */}{' '}
      Внесение планктонной хлореллы в&nbsp;водоем возможно в&nbsp;течениe всего летнего сезона. Повторное внесение
      позволяет улучшить эффект, дополнительно уменьшить количество сине-зеленых водорослей, приводящих
      к&nbsp;&laquo;цветению&raquo; воды.
    </div>
  </div>
);

export default Promo;
