import { Fragment } from 'react';
import Container from 'components/Layouts/Container.jsx';

function items(items) {
  return items.map((item) => {
    return (
      <div className="summary__item" key={item.title}>
        <div className="summary__title" dangerouslySetInnerHTML={{ __html: item.title }} />
        <div className="summary__text" dangerouslySetInnerHTML={{ __html: item.text }} />
      </div>
    );
  });
}

function summary(props) {
  return (
    <Container modificator="container-limit">
      <div className="summary">
        <div className="container">
          <div className="summary__container">{items(props.items)}</div>
        </div>
      </div>
    </Container>
  );
}

export default summary;
