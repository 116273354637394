// import Svg from 'react-inlinesvg';

import logo from 'images/application/brand/main_logo.svg';

const rootPath = '/';

const MainLogo = () => (
  <a href={rootPath} className="unstyled_link" title="Вернуться на домашнюю страницу">
    <img src={logo} className="main_logo" />
  </a>
);

export default MainLogo;
