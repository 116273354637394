import { TextField, NumberField, SelectField } from 'components/Form/Fields';
import { Field } from 'react-final-form';
import ZonesMap from 'additional/Map/ZonesMap';

class MegapolisDeliveryForm extends React.Component {
  render() {
    const {delivery_dates, delivery_times} = this.props.stats
    const timeSelect = (delivery_times && Object.keys(delivery_times).length > 0 && delivery_dates && Object.keys(delivery_dates).length > 0)
    return (
      <>
        <div className='form__row'>
          <p><strong>ВАЖНО!</strong> Доставка по Москве и обозначенной зоне вокруг МКАД осуществляется 
             транспортной компанией Мегаполис24, <strong>оплата заказа производится покупателем при получении заказа</strong>, 
             информация о доставке будет выслана по указанному выше телефону. Курьер осуществляет звонок за 15-30 минут до 
             прибытия. Простой 15 минут стоит 100 рублей, первые 15 минут бесплатно.</p>
        </div>
        <div className='form__row'>
          <Field name='megapolis' component={ZonesMap} />
        </div>
        <div className='form__row'>
          <TextField name='megapolis.appartment' label='Номер квартиры, подъезд, код домофона (если нужно)' />
        </div>
        {timeSelect && (
          <div className="form__row horizontal">
            <div>
              <SelectField name='megapolis.date' disabled={Object.keys(delivery_dates).length <= 1} items={delivery_dates} label='Дата доставки' />
            </div>
            <div>
              <SelectField name='megapolis.time' disabled={Object.keys(delivery_times).length <= 1} items={delivery_times} label='Время доставки' />
            </div>
          </div>
        )}
        {this.props.zone && this.props.zone != 6 && (          
          <div className='form__row'>
            <p><strong>Ориентировочная стоимость доставки {this.props.stats.delivery_price}</strong></p>
            <p>Доставка оплачивается при получении.</p>
          </div>
        )}
        {this.props.zone == 6 && (
          <div className='form__row'>
            <p>Указанный адрес вне зоны доставки ТК Мегаполис24.<br/> 
            <strong>Используйте доставку ТК DPD в блоке ниже</strong> или свяжитесь с нашим менеджером для оформления вам особой доставки.</p>
          </div>        
        )}
      </>
    );
  }
}

export default MegapolisDeliveryForm