import React from 'react';
import LoaderIcon from 'react-loader-icon';
import { string, bool } from 'prop-types';

const Submit = ({ children, disabled }) => (
  <button className="button button-submit" disabled={disabled} type="submit">
    {disabled ? (
      <span style={{ color: 'white' }}>
        <LoaderIcon size={24} color="white" type="bars" />
      </span>
    ) : (
      children
    )}
  </button>
);

Submit.propTypes = {
  children: string,
  disabled: bool,
};

Submit.defaultProps = {
  children: 'Отправить',
  disabled: false,
};

export default Submit;
