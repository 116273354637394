import React from 'react';

import visa from 'images/application/icons/acquiring/visa.svg';
import mastercard from 'images/application/icons/acquiring/mastercard.svg';
import mir from 'images/application/icons/acquiring/mir.svg';
import applePay from 'images/application/icons/acquiring/apple-pay.svg';
import googlePay from 'images/application/icons/acquiring/google-pay.svg';
import sber from 'images/application/icons/acquiring/sber.svg';
import alfaBank from 'images/application/icons/acquiring/alfa-bank.svg';

const icons = [visa, mastercard, mir, applePay, googlePay, sber, alfaBank];

function Acquiring() {
  return (
    <div className="acquiring">
      <p className="acquiring__title">Оплата производится через интернет-эквайринг от Сбербанка</p>

      <ul className="icon-list">
        {icons.map((item) => (
          <li className="icon-list__item" key={item}>
            <img src={item} className="acquiring__image" alt="" />
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Acquiring;
