import Svg from 'react-inlinesvg';
import image from 'images/application/ui/close.svg';

function Close() {
  return (
    <span role="closePanel">
      <Svg className="close" src={image} />
    </span>
  );
}

export default Close;
