import 'stellar.js';

import isTouch from 'touch-screen';

function parallax($container) {
  const config = {
    horizontalScrolling: false,
    responsive: true,
  };

  const hideBackground = () => {
    $('html, body').css({'background': 'none'});
  }

  const isHideBackground = $container.height() < 2000;

  console.log($container.height());

  if (isHideBackground) {
    hideBackground();
  }

  if (!isTouch()) {
    $.stellar(config);
  }
}

export default parallax;
