import debouncing from 'utils/debouncing';
import axios from 'utils/axios';
import {Label} from 'components/Form/Fields';

class CitySelector extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      items: [],
      value: props.input.value.name || ''
    }
    this.debSearch = debouncing(this.search.bind(this), 1000)
  }
  render() {
    const {input, meta, label} = this.props;
    const value = input.value.dpd_id && !meta.active ? `${input.value.name}, ${input.value.region_name}` : this.state.value
    return (
      <div className='city_selector'>
        <Label text={this.props.label} />
        <input type='text' className='input' 
           onChange={this.onInputChange.bind(this)} 
           onFocus={input.onFocus} 
           onBlur={input.onBlur}
           value={value} 
        />
        {this.state.items && this.state.items.length > 0 && (
          <div className='items'>
            {this.state.items.map((item, i) => (
              <div className='item' key={i} onClick={() => {this.select(item)}}>{item.name}, {item.region_name}</div>
            ))}
          </div>
        )}
      </div>
    )
  }
  onInputChange(e) {
    const value = e.target.value
    this.setState({value, items: []})
    this.debSearch(value)
  }
  search(value) {
    axios.get('/city_search', {params: {query: value}}).then(res => {
      this.setState({items: res.data.items || []})
      if(!res.data.items || res.data.items.length == 0) {
        this.props.input.onChange({dpd_id: 0, name: this.state.value, region_name: ''})
      }
    })
  }
  select(item) {
    this.setState({value: item.name, items: []})
    this.props.input.onChange(item)
  }
}

export default CitySelector;