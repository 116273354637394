import React from 'react';
import { Form } from 'react-final-form';

import Fields from 'components/Form/Fields';
import { AutosaveDebounce } from 'components/Form/Autosave';

class ContactsForm extends React.Component {
  render() {
    return (
      <div className="form-card">
        <h3 className="form-card__title">2. Укажите ваши контактные данные</h3>
        <div className="form-card__body">
          <div className="form-contacts">
            <Form onSubmit={this.props.onSubmit.bind(this)} initialValues={this.props.contact}>
              {(form) => (
                <form className="form" onSubmit={form.handleSubmit}>
                  <AutosaveDebounce debounce={1000} save={form.handleSubmit} />
                  <div className="form__row">
                    <Fields.TextField name="surname" label="Фамилия" />
                  </div>
                  <div className="form__row">
                    <Fields.TextField name="name" label="Имя и отчество" />
                  </div>
                  <div className="form__row">
                    <Fields.TextField name="phone" label="Телефон" />
                  </div>
                  <div className="form__row">
                    <Fields.TextField name="email" label="Email" />
                  </div>
                </form>
              )}
            </Form>

            <div className="form__info form-contacts__right">
              <p>Все поля обязательны для&nbsp;заполнения.</p>
              <p>
                На&nbsp;указанный email отправляются подробности заказа, а&nbsp;сообщением на&nbsp;телефон вы&nbsp;получите информацию о&nbsp;доставке от&nbsp;транспортной компании.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ContactsForm;
