import React from 'react';
import { Form } from 'react-final-form';
import cx from 'classnames';

import { AutosaveDebounce } from 'components/Form/Autosave';

import ProductCounter from './ProductCounter';

const Image = ({ image }) => {
  const img = require(`images/application/illustrations/${image}`);

  return (
    <span className="form-product__image">
      <img className="image image-responsive" src={img} alt="Альготек" />
    </span>
  );
};

class ProductsForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      product: 'aqua',
      dummyProducts: [
        {
          id: 'aqua',
          name: 'АЛЬГОТЕК АКВА',
          description:
            'Суспензия микроводоросли - хлорелла для комплексной очистки и биологической реабилитации пресных водоемов.',
          properties:
            'Штамм Chlorella Vulgaris GKO Плотность не менее 50 млн. живых клеток планктонной хлореллы на 1 мл',
          options: [
            ...props.products
              .filter((el) => el.name.split('/')[0] === 'aqua')
              .map((el) => ({ price: el.price, volume: el.name.split('/')[1], perL: el.litre_price, id: el.id })),
          ],
          image: 'product2021.png',
          downcription: (
            <>
              <p>Для однократного внесения в водоем</p>

              <p>
                до&nbsp;150&nbsp;кв.&nbsp;м&nbsp;достаточно&nbsp;5л
                <br />
                до&nbsp;500&nbsp;кв.&nbsp;м&nbsp;достаточно 10л
                <br />
                до&nbsp;1500&nbsp;кв.&nbsp;м&nbsp;достаточно 20л
                <br />
                от&nbsp;1500&nbsp;кв.&nbsp;м&nbsp;до&nbsp;1га по&nbsp;30л на&nbsp;каждый 1&nbsp;га
                <br />
              </p>

              <p>
                В&nbsp;течениe сезона рекомендуем 3-кратное внесение
                <br />
                <a href="/methods" className="link link-color" target="_blank" role="openPanel">
                  Подробнее о&nbsp;методике
                </a>
              </p>
            </>
          ),
        },
        {
          id: 'split',
          name: 'ALGOTEC SPLIT',
          description:
            'Альготек СПЛИТ - биопрепарат для очистки водоемов и устранения донных отложений. Простой и безопасный способ сделать водоем чистым, избавиться от ила и устранить неприятный запах.',
          properties: 'Cуспензионный биопрепарат нового поколения на основе комплекса сапрофитных микроорганизмов. ',
          options: [
            ...props.products
              .filter((el) => el.name.split('/')[0] === 'split')
              .map((el) => ({ price: el.price, volume: el.name.split('/')[1], perL: el.litre_price, id: el.id })),
          ],
          image: 'algosplitsecond.png',
          withButton: true,
          downcription: (
            <>
              <p>Кратность внесения - 1-3 раза за сезон:</p>
              <p>
                • Первое внесение производится ранней весной (март/апрель), при температуре воды 10- 12С; <br />
                • Второе внесение производится в начале лета (июнь/июль), при температуре воды 15-20С; <br />• Третье
                внесение производится в октябре/ноябре;
              </p>
            </>
          ),
        },
      ],
    };
  }

  render() {
    console.log(this.props);

    const current = this.state.dummyProducts.find((p) => p.id === this.state.product);
    return (
      <div className="form-card">
        <h3 className="form-card__title">1. Укажите необходимое количество канистр</h3>

        <div className="form-card__body">
          <div className="form-product">
            <div className="form-card_buttons">
              <div
                onClick={() => {
                  this.setState({ product: 'aqua' });
                }}
                className={cx('form-card_button', { active: this.state.product === 'aqua' })}
              >
                Аква
              </div>
              <div
                onClick={() => {
                  this.setState({ product: 'split' });
                }}
                className={cx('form-card_button', { active: this.state.product === 'split' })}
              >
                Сплит
              </div>
            </div>
            <header className="form-product__header">
              <p>
                <strong>{current.description}</strong>
              </p>

              <p>{current.properties}</p>
            </header>

            <div className="form-product__body">
              <Form initialValues={this.props.purchased} onSubmit={this.props.onSubmit.bind(this)}>
                {(form) => (
                  <form className="form" onSubmit={form.handleSubmit}>
                    <AutosaveDebounce debounce={1000} save={form.handleSubmit} />

                    {current.options.map(({ price, volume, perL, id }) => (
                      <div className="form__row" key={price}>
                        <ProductCounter
                          id={id}
                          litrePrice={perL}
                          name={volume}
                          price={price}
                          quantities={this.props.quantities}
                        />
                      </div>
                    ))}
                  </form>
                )}
              </Form>

              <div className="form-product__right">
                <Image image={current.image} />
              </div>
            </div>
            <div className="form-product__instruction">{current.downcription}</div>
          </div>
        </div>

        <footer className="form-card__footer">
          <div>
            <strong>{`Итого, стоимость товаров: ${this.props.stats.price}`}</strong>{' '}
            <span>{`(суммарный вес: ${this.props.stats.weight} кг)`}</span>
          </div>

          <div />
        </footer>
      </div>
    );
  }
}

export default ProductsForm;
