import fish from 'images/application/backgrounds/fish_farms_big.png';
import pond from 'images/application/backgrounds/private_ponds_big.png';
import recreation from 'images/application/backgrounds/recreational_ponds_big.png';
import reservoir from 'images/application/backgrounds/reservoirs_big.png';

const covers = {
  fish,
  pond,
  recreation,
  reservoir,
};

function Description({ items }) {
  return (
    <div className="page_header__footer">
      <div className="page_header__left">
        <ul className="page_header_description">
          {items.map((item) => {
            return (
              <li className="page_header_description__item" key={item} dangerouslySetInnerHTML={{ __html: item }} />
            );
          })}
        </ul>
      </div>
    </div>
  );
}

function header(props) {
  const background = {
    backgroundImage: `url(${covers[props.cover]})`,
  };

  return (
    <div className="container container-limit">
      <div className="page_header" style={background}>
        <div className="page_header__container container">
          <h1 dangerouslySetInnerHTML={{ __html: props.title }} className="page__title" />
          {props.description && <Description items={props.description} />}
        </div>
      </div>
    </div>
  );
}

export default header;
