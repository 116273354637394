// import Svg from 'react-inlinesvg';

import image01 from 'images/application/illustrations/calc01.svg';
import image02 from 'images/application/illustrations/calc02.svg';
import image03 from 'images/application/illustrations/calc03.svg';

function showItems() {
  return (
    <ul className="calculation_list">
      <li className="calculation_list__item">
        <div className="calculation_list__text">
          Для водоемов площадью от&nbsp;5&nbsp;до&nbsp;1000&nbsp;м кв&nbsp;достаточно 15 л суспензии на&nbsp;сезон
          за&nbsp;три внесения, это три заказа 5&nbsp;литров канистр суспензии, стоимостью по&nbsp;3450&nbsp;руб.
        </div>
        <div className="calculation_list__icon">
          <img className="calculation_list__image" src={image01} />
        </div>
      </li>

      <li className="calculation_list__item">
        <div className="calculation_list__text">
          {' '}
          Для водоемов площадью от&nbsp;1000 до&nbsp;5000&nbsp;м кв&nbsp;достаточно три внесения по&nbsp;15&nbsp;литров,
          мы рекомендуем два внесения канистр по 30 литров, стоимостью 14 500 руб. (это незначительно дороже, чем заказ
          канистрами по&nbsp;5 л).
        </div>
        <div className="calculation_list__icon">
          <img className="calculation_list__image" src={image02} />
        </div>
      </li>
      <li className="calculation_list__item">
        <div className="calculation_list__text">
          Для водоемов площадью более 0,5 га и&nbsp;более расчет производится исходя из&nbsp;30 л суспензии на&nbsp;1 га
          за&nbsp;одно внесение. При заказе более 500 л, действуют специальные условия, уточняйте их у менеджера.
        </div>
        <div className="calculation_list__icon">
          <img className="calculation_list__image" src={image03} />
        </div>
      </li>
    </ul>
  );
}

function Content() {
  return (
    <div className="calculation_tabs">
      <div className="calculation_tabs__body">{showItems()}</div>
    </div>
  );
}

export default Content;
