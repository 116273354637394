import windowScrollPosition from 'window-scroll-position';

function header($window, $header) {
  function init() {
    const modificator = 'header-sticked';
    const breakPoint = 10;

    $window.on('scroll load', () => {
      const scrollPositionTop = windowScrollPosition().top;

      if (scrollPositionTop > breakPoint) {
        $header.addClass(modificator);
      } else {
        $header.removeClass(modificator);
      }
    });
  }

  init();
}

export default header;
