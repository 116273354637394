import Container from 'components/Layouts/Container.jsx';
import MainLogo from 'components/UI/MainLogo.jsx';

const BackHome = () => (
  <div className="back_home">
    <Container>
      <div className="back_home__container">
        <div className="back_home__left">
          <MainLogo />
        </div>

        <div className="back_home__right">
          <h3 className="back_home__title">
            Узнайте больше о&nbsp;нашей хлорелле, производстве и&nbsp;гарантиях качества
          </h3>

          <p className="back_home__hint">
            Отзывы, принцип действия, отличие от&nbsp;конкурентов, сертификаты и&nbsp;документы, возможности применения
            в&nbsp;водоемах других типов.
          </p>

          <a href="/" className="button button-primary">
            Подробнее о продукте
          </a>
        </div>
      </div>
    </Container>
  </div>
);

export default BackHome;
