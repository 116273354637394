
// Обёртка, вызывающая callback только один раз после только, как пройдёт timeout с момента последнего вызова. 
// При вызове передаются последние применённые аргументы. 
export default (callback, timeout)=> {
  let args = null
  let timer = null
  return (a, b, c) => {
    args = [a, b, c]
    if(timer) {
      clearTimeout(timer)
    }
    timer = setTimeout(() => {timer=null; callback(...args)}, timeout)
  }
}