import axios from 'utils/axios';
import clean from 'utils/clean';
import React from 'react';

import Container from 'components/Layouts/Container';
import Button from 'components/UI/Button';

import ContactForm from './ContactsForm';
import DeliveryForm from './DeliveryForm';
import ProductsForm from './ProductsForm';
import Acquiring from './Acquiring';
import Logo from './Logo';

const endpoint = '/order';

class OrderForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cart_id: props.cart.id,
      report: props.report,
      stats: props.stats,
    };

    this.title = props.legend.title;
    this.intro = props.legend.text;
    this.footer = props.legend.footer;
    this.disclaimer = props.legend.disclaimer;
    this.quantities = props.quantities;
  }

  render() {
    const { products, contact, delivery } = this.props.cart;
    const { report, stats } = this.state;

    return (
      <div className="order-page">
        <Container>
          <header className="order-page__header">
            <Logo />

            <h1 className="order-page__title page-title">{this.title}</h1>
            {this.intro.map((item, i) => (
              <p dangerouslySetInnerHTML={{ __html: clean(item) }} key={i} />
            ))}
          </header>

          <ProductsForm
            onSubmit={this.submitHandler('products')}
            products={this.props.products}
            purchased={products}
            quantities={this.quantities}
            stats={stats}
          />

          <ContactForm contact={contact} onSubmit={this.submitHandler('contact')} />

          <DeliveryForm
            delivery={delivery}
            delivery_types={this.props.delivery_types}
            stats={this.state.stats}
            onSubmit={this.submitHandler('delivery')}
          />

          <footer className="order-page__footer">
            <div className="order-page__summary">
              <div className="order-summary">
                <div className="order-summary__row">
                  <span className="order-summary__cell">Стоимость товаров</span>
                  <span className="order-summary__cell">{this.state.stats.price}</span>
                </div>

                <div className="order-summary__row">
                  <span className="order-summary__cell">
                    Ориентировочная стоимость доставки
                    <br />
                    (оплачивается при получении)
                  </span>
                  <span className="order-summary__cell order-summary__cell">
                    {this.state.stats.delivery_price || 'неизвестна'}
                  </span>
                </div>
              </div>
            </div>

            <div className="order-page__submit">
              <Button type="submit" disabled={!report.complete} onClick={this.confirm.bind(this)} modificator="wide">
                Оплатить товары
                {` (${this.state.stats.price})`}
              </Button>
            </div>

            {!report.complete && (JSON.stringify(report)) && (
              <p className="order-page__error">Не&nbsp;все обязательные поля для оформления заказа заполнены.</p>
            )}

            <p dangerouslySetInnerHTML={{ __html: clean(this.footer) }} className="order-page__consultation" />
            <p dangerouslySetInnerHTML={{ __html: clean(this.disclaimer) }} className="order-page__disclaimer" />

            <Acquiring />
          </footer>
        </Container>
      </div>
    );
  }

  confirm() {    
    window.location.assign(`/order/${this.state.cart_id}/confirm`);
  }

  submitHandler(name) {
    return (data) => this.submit(name, data);
  }

  async submit(name, data) {
    return await axios
      .post(endpoint, { facet: name, [name]: data })
      .then((res) => {
        this.setState({ cart_id: res.data.cart.id, report: res.data.report, stats: res.data.stats });
        return res.data.report[name].errors;
      })
      .catch((err) => {
        window.console.log(err);
      });
  }
}

export default OrderForm;
