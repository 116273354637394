import cn from 'classnames';
import React from 'react';

export default (props) => {
  const { input, meta, items, label, ...rest } = props;

  return (
    <div className="delivery-selector__row">
      <div className="tabs">
        <p className="tabs__title label">{label}</p>
        <ul className="tabs__list">
          {Object.keys(items).map((key) => {
            const { label, price } = items[key];
            const cnButton = cn('tabs__button', { 'tabs__button--active': input.value === key });
            return (
              <li className="tabs__tab" key={key}>
                <button className={cnButton} type="button" onClick={() => input.onChange(key)}>
                  <p>{label}</p>
                  <p>{price}</p>
                </button>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};
