const style = { display: 'block', color: 'gray', marginTop: '35px' };

const Confirm = () => (
  <span style={style}>
    Отправляя данную форму вы соглашаетесь с{' '}
    <a href="/privacy_policy" className="link link-color" target="_blank">
      Политикой конфиденциальности
    </a>
  </span>
);

export default Confirm;
