import splitImage from 'images/application/illustrations/algosplitsecond.png';
import canisters from 'images/application/illustrations/canisters.png';

const split = ({
  title,
  ad,
  secondary_title,
  description,
  problems,
  features,
  remember,
  results,
  methods,
  techonology,
}) => {
  return (
    <div className="split-page">
      <div className="split-background">
        <h1>
          <b>Альготек СПЛИТ</b> — {title}
        </h1>
        <p>{ad}</p>
      </div>
      <div className="split-main">
        <h1>
          {secondary_title[0]} <br /> {secondary_title[1]}
        </h1>
        <div className="split-info">
          <p dangerouslySetInnerHTML={{ __html: description }}></p>
          <img src={splitImage} />
        </div>
        <div className="split-problems">
          <h1>{`Используйте Альготек СПЛИТ, если вы
сталкиваетесь с этими проблемами`}</h1>
          <div className="problems-grid">
            {problems.map((problem) => (
              <div className="split-problem" key={problem.id}>
                <img className="problem-icon" src={require(`images/application/illustrations/${problem.icon}.svg`)} />
                {problem.text}
              </div>
            ))}
          </div>
        </div>
        <div className="split-target">
          <h1>Кому подходит продукт</h1>
          <div className="target-grid">
            <div className="grid-t-row paid">Платные рыбалки</div>
            <div className="grid-t-row fishing">Рыбные хозяйства</div>
            <div className="grid-b-row bio">Биоплато</div>
            <div className="grid-b-row private">Частные водоёмы</div>
            <div className="grid-b-row recreational">Рекреационные зоны</div>
          </div>
        </div>
        <div className="split-features">
          <h1>Преимущества</h1>
          <div className="features-grid">
            {features.map((feature) => (
              <div className="features-option" key={feature.id}>
                <img className="problem-icon" src={require(`images/application/illustrations/${feature.icon}.svg`)} />
                {feature.text}
              </div>
            ))}
          </div>
        </div>
        <div className="split-remember">
          <h1>важно помнить</h1>
          <ul className="remember-grid">
            {remember.map((r) => (
              <li key={r.id} dangerouslySetInnerHTML={{ __html: r.text }}></li>
            ))}
          </ul>
        </div>
        <div className="split-results">
          <h1>При регулярном использовании Альготек SPLIT вы получите результаты!</h1>
          {results.split('.').map((result, i) => (
            <div key={i}>{result}</div>
          ))}
        </div>
        <div className="split-method">
          <h1>Способ применения</h1>
          <ul dangerouslySetInnerHTML={{ __html: methods }}></ul>
          <img src={canisters} />
        </div>
        <div className="split-technology">
          <h1>Комплексная технология биоремедиации от компании Альготек</h1>
          <ul dangerouslySetInnerHTML={{ __html: techonology }}></ul>
        </div>
      </div>
    </div>
  );
};

export default split;
