function FormSuccess({ callback }) {
  return (
    <div className="response response-success">
      <div className="container">
        <div className="response__inner">
          <div className="response__container">
            <h3 className="response__title">Благодарим за ваш заказ!</h3>
            <p className="response__message">
              В&nbsp;ближайшее время наш продавец-консультант свяжется с&nbsp;вами и&nbsp;ответит на&nbsp;вопросы.
            </p>
            <a href="javascript:void(0);" role="closePanel" className="link link-inverse link-bold">
              Закрыть окно
            </a>
            <br />
            <br />
            <a href="javascript:void(0);" onClick={() => callback()} className="link link-inverse link-bold">
              Сформировать еще один запрос
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FormSuccess;
